<template>
  <section class="section">
    <b-button @click="addTerminal()" size="is-small" class="button is-info is-pulled-right mb-1">Novo</b-button>
    <p class="subtitle">Terminais</p>

    <table class="table is-narrow is-fullwidth">
      <tr>
        <th>Nome</th>
        <th>Media</th>
        <th>Ativo</th>
        <th></th>
      </tr>
      <tr v-for="terminal in terminals" :key="terminal._id">
        <td class="col-local">{{ terminal.name }}</td>
        <td class="col-media">
          <p class="text-truncate">{{ terminal.media }}</p>
        </td>
        <td>
          <b-field>
            <b-checkbox @input="toggleActiveTerminal(terminal)" v-model="terminal.active"></b-checkbox>
          </b-field>
        </td>
        <td>
          <div class="buttons">
            <a v-show="terminal.active" :href="`https://maxqueue.com.br/#/${terminal.key}`" class="button"
              size="is-small"><b-icon icon="television"> </b-icon></a>
            <a v-show="terminal.active" @click.prevent="editTerminal(terminal)" class="button" size="is-small"><b-icon
                icon="pencil-outline"> </b-icon></a>
            <a @click="delTerminal(terminal._id)" class="button" size="is-small">
              <b-icon icon="trash-can-outline" type="is-danger"> </b-icon></a>
          </div>
        </td>
      </tr>
    </table>

    <!-- terminal form -->
    <b-modal v-model="showModalTerminalForm" has-modal-card :destroy-on-hide="false" :can-cancel="false">
      <div class="modal-card" style="width: 780px">
        <header class="modal-card-head">
          <p class="modal-card-title">Terminal</p>
          <button type="button" class="delete" @click="showModalTerminalForm = false" />
        </header>

        <section class="modal-card-body">
          <ValidationObserver ref="form">
            <form @submit.prevent="onSubmit">
              <!-- nome -->
              <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                <b-field label="Nome" label-position="on-border" :message="errors[0]"
                  :type="errors[0] ? 'is-danger' : ''" class="mb-3">
                  <b-input v-model="terminal.name" type="text"></b-input>
                </b-field>
              </ValidationProvider>

              <Address :isRequired="false" :address="terminal.address" @setAddress="(v) => (terminal.address = v)" />

              <!-- media -->
              <b-field label="Media" label-position="on-border">
                <b-input v-model="terminal.media"></b-input>
              </b-field>
              <b-field label="Ação" class="mb-0">
                <b-button type="is-info" native-type="submit">Enviar</b-button>
              </b-field>
            </form>
          </ValidationObserver>
        </section>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Address from "@/components/Address.vue";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
class Terminal {
  constructor() {
    this.name = "";
    this.address = {};
    this.media = "";
    this.active = true;
  }
}
export default {
  name: "Register",
  components: {
    ValidationProvider,
    ValidationObserver,
    Address,
  },
  data() {
    return {
      terminal: new Terminal(),
      terminals: [],
      selectedTerminal: "",
      doctors: [],
      showModalTerminalForm: false,
    };
  },
  computed: {},
  methods: {
    addTerminal() {
      this.terminal = new Terminal();
      this.showModalTerminalForm = true;
    },
    editTerminal(terminal) {
      this.terminal = JSON.parse(JSON.stringify(terminal));
      this.showModalTerminalForm = true;
    },
    onSubmit() {
      const id = this.terminal._id;
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        if (!id) {
          this.$http.post(`terminal/admin`, this.terminal).then((response) => {
            this.terminal.key = response.data.key;
            this.terminals.push(this.terminal);
            // Resetting Values
            this.terminal = new Terminal();
            this.showModalTerminalForm = false;
          });
        }
        if (id) {
          this.$http.put(`terminal/admin/${id}`, this.terminal).then(() => {
            this.terminals = this.terminals.map((v) => {
              if (v._id == id) {
                v.name = this.terminal.name;
                v.media = this.terminal.media;
                v.active = this.terminal.active;
              }
              return v;
            });
            // Resetting Values
            this.terminal = new Terminal();
            this.showModalTerminalForm = false;
          });
        }
      });
    },
    toggleActiveTerminal(terminal) {
      this.$http.put(`terminal/admin/${terminal._id}`, terminal).then(() => {
        this.$buefy.toast.open({
          message: "Operação realizada",
          type: "is-success",
          position: "is-top-right",
        });
      });
    },
    delTerminal(terminal_id) {
      const self = this;
      self.$buefy.dialog.confirm({
        title: "Excluir terminal",
        message: "Essa operação não poderá ser desfeita!",
        confirmText: "Sim Excluir",
        type: "is-danger",
        hasIcon: false,
        onConfirm: function () {
          self.$http
            .put(`terminal/admin/${terminal_id}`, { deleted: true })
            .then(() => {
              self.terminals = self.terminals.filter(
                (f) => f._id != terminal_id
              );
              self.$buefy.toast.open({
                message: "Operação realizada",
                type: "is-success",
                position: "is-top-right",
              });
            });
        },
      });
    },
    //adiciona o terminal para o profissional
    // setOfficeToTerminal(terminal) {
    //   console.log(terminal);
    //   this.$http
    //     .put(`terminal/${this.selectedTerminal._id}/${terminal._id}`, {
    //       callTo: terminal.callTo,
    //     })
    //     .then(() => {
    //       //doctor.terminals[this.selectedTerminal] = doctor.callTo;
    //       this.$buefy.toast.open({
    //         message: "Operação realizada",
    //         type: "is-success",
    //         position: "is-top-right",
    //       });
    //     });
    // },
  },
  created() {
    this.$http.get(`terminal/admin`, { useCache: true }).then((response) => {
      this.terminals = response.data;
    });
  },
};
</script>
<style scoped>



  .text-truncate {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  table {
    width: 80%;
  }

  td.col-local {
    width: 25% !important;
  }

  td.col-media {
    width: 25% !important;
  }

  td.col-actions {
    width: 25% !important;
  }

  td.doctor {
    width: 50% !important;
  }

  td.terminal {
    width: 30% !important;
  }
</style>