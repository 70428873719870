<template>
  <div>
    <!-- address -->
    <div class="columns is-variable is-1 mb-0">
      <div class="column is-4">
        <ValidationProvider
          name="zip"
          :rules="isRequired ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-field
            :message="errors[0]"
            :type="errors[0] ? 'is-danger' : ''"
            label="Cep"
            label-position="on-border"
          >
            <b-input
              v-on:keyup.native="getAddress"
              v-model="address.zip"
              v-mask="'##.###-###'"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-8">
        <ValidationProvider
          name="street"
          :rules="isRequired ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-field
            :message="errors[0]"
            :type="errors[0] ? 'is-danger' : ''"
            label="Rua/Av."
            label-position="on-border"
          >
            <b-input v-model="address.street"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
    </div>

    <div class="columns is-variable is-1 mb-0">
      <div class="column is-4">
        <ValidationProvider
          name="number"
          :rules="isRequired ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-field
            :message="errors[0]"
            :type="errors[0] ? 'is-danger' : ''"
            label="Número"
            label-position="on-border"
          >
            <b-input v-model="address.number"></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-3">
        <ValidationProvider name="complement">
          <b-field label="Comp." label-position="on-border">
            <b-input v-model="address.complement"></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-5">
        <ValidationProvider
          name="neighborhood"
          :rules="isRequired ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-field
            :message="errors[0]"
            :type="errors[0] ? 'is-danger' : ''"
            label="Bairro"
            label-position="on-border"
          >
            <b-input v-model="address.neighborhood"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
    </div>

    <div class="columns is-variable is-1 mb-0">
      <div class="column is-8">
        <ValidationProvider
          name="city"
          :rules="isRequired ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-field
            :message="errors[0]"
            :type="errors[0] ? 'is-danger' : ''"
            label="Cidade"
            label-position="on-border"
          >
            <b-input v-model="address.city"></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-4">
        <ValidationProvider
          name="state"
          :rules="isRequired ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-field
            :message="errors[0]"
            :type="errors[0] ? 'is-danger' : ''"
            label="Uf"
            label-position="on-border"
          >
            <b-input v-model="address.state"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
    </div>
    <!-- end address -->
  </div>
</template>

<script>
// Local Directive
import { mask } from "vue-the-mask";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
export default {
  name: "Address",
  props: ["address", "isRequired"],
  directives: { mask },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      //address: {},
      foundAddress: false,
    };
  },
  methods: {
    getAddress: function () {
      this.foundAddress = false;
      if (/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/.test(this.address.zip)) {
        let zip = this.address.zip.replace(/\D/g, "");
        this.$http
          .get(`https://viacep.com.br/ws/${zip}/json`)
          .then((response) => {
            if (response.erro) {
              this.address = {};
              this.foundAddress = false;
              return;
            }
            const address = {
              zip: response.data.cep,
              street: response.data.logradouro,
              neighborhood: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf,
            };
            //console.log(address);
            this.$emit("setAddress", address);
            this.foundAddress = true;
          });
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>